import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import Gleap from 'gleap';

const routes = constructRoutes(document.querySelector("#single-spa-layout"), {
  loaders: {
    navLoader: "<h1></h1>",
    workflowLoader: `<div style="height: 80vh; justify-content: center;align-items: center;display: flex;">
                     <img src="https://idflow-workflow-images.s3.ap-south-1.amazonaws.com/loader.png" />
                    </div>`,

    chatbotLoader: `<div style="height: 80vh; justify-content: center;align-items: center;display: flex;">
                    <img src="https://idflow-workflow-images.s3.ap-south-1.amazonaws.com/loader.png" />
                    </div>`,

    userLoader: `<div style="height: 80vh; justify-content: center;align-items: center;display: flex;">
                 <img src="https://idflow-workflow-images.s3.ap-south-1.amazonaws.com/loader.png" />
                 </div>`,
  },
  errors: {
    navError: `<div style="height: 80vh; justify-content: center;align-items: center;display: flex;flex-direction: column;       gap:1rem">
                  <img src="https://idflow-workflow-images.s3.ap-south-1.amazonaws.com/loader.png" />
                  <h3>Error Occured</h3>
                  <p>The request is not available please try again or check your internet connection</p>
                  <button>Go Back</button>
              </div>`,
    workflowError: `<div style="height: 80vh; justify-content: center;align-items: center;display: flex;flex-direction: column;       gap:1rem">
    <img src="https://idflow-workflow-images.s3.ap-south-1.amazonaws.com/loader.png" />
    <h3>Error Occured</h3>
    <p>The request is not available please try again or check your internet connection</p>
    <button>Go Back</button>
</div>`,
    chatbotError: `<div style="height: 80vh; justify-content: center;align-items: center;display: flex;flex-direction: column;       gap:1rem">
    <img src="https://idflow-workflow-images.s3.ap-south-1.amazonaws.com/loader.png" />
    <h3>Error Occured</h3>
    <p>The request is not available please try again or check your internet connection</p>
    <button>Go Back</button>
</div>`,
    userError: `<div style="height: 80vh; justify-content: center;align-items: center;display: flex;flex-direction: column;       gap:1rem">
    <img src="https://idflow-workflow-images.s3.ap-south-1.amazonaws.com/loader.png" />
    <h3>Error Occured</h3>
    <p>The request is not available please try again or check your internet connection</p>
    <button>Go Back</button>
</div>`,
  },
});
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

Gleap.initialize("Hthe4tdx2Enn0tQEtKGSEdVTZadsh5cw");

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);

System.import("@idflow/styles").then(() => {
  // Activate the layout engine once the styleguide CSS is loaded
  layoutEngine.activate();
  start();
});
// layoutEngine.activate();
// start();
